import styled from 'styled-components'

export const Menu = styled.div`
  background: url(${(props) => props.background});
  background-position: top right;
  background-size: cover;
  height: 100vh;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;

  nav{
    display: flex;
    flex-direction: column;

    a {
      margin-left: 5px;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  nav {
    margin-top: 30px;
    a {
      padding: 1rem;
      color: ${(props) => props.theme.palette.gray[800]};
      font-size: 13px;
      text-decoration: none;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-top: 10px;
  }

  .logo {
    width: 62px;
    margin-top: 30px;
  }

  main{
    padding: 2rem;
    flex-grow: 1;
    width: 89%;
  }

  .category{
    display: flex;
    align-items: center;
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[900]};
    font-size: 1.4rem;

    svg {
      margin-right: 5px;
    }
  }

  .second-category {
    margin-top: 20px;
  }

  .main-nav {
    font-weight: bold;
    color: ${(props) => props.theme.palette.gray[900]};
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__icon {
      display: flex;
      align-items: center;
    }

    &__icon svg {
      margin-right: 0;
      margin-left: 10px;
      cursor: pointer;
    }

    .general-search {
      margin-right: 15px;
    }

    .nav-screens {
      flex-grow: 1;
      display: flex;

      .nav-screen-link {
        padding: 5px;
      }
    }

    .user-permission {
      font-size: 11px;
      position: absolute;
    }

    .user-role {
      font-size: 11px;
      position: absolute;
    }
  }

  .main-component {
    padding: 4rem 4rem 1rem 4rem;
  }

  .selected {
    color: ${(props) => props.theme.palette.primary};
    font-weight: bold;
  }

  .version-text {
    align-self: flex-end;
    color: #888;
  }
`
