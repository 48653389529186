import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import CircularProgress from '@material-ui/core/CircularProgress'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Button } from 'ui/components'

import { fetchPlanServicesList as fetchPlanCpfServicesList } from 'redux/planCpfServices/actions'
import { fetchPlanServicesList as fetchPlanCnpjServicesList } from 'redux/planCnpjServices/actions'

import { getLastGeneratedPlan, postGeneratePlan } from 'services/apiMotorBgc'

import analysisStatusConstants from 'utils/constants/analysisStatus'
import { GROUP_ID } from 'utils/constants/groupService'
import { POLE_TYPE_TO_CONSULT } from 'utils/constants/poleTypes'
import { PANEL_ENVS } from 'utils/constants/environments'
import reportError from 'utils/errorReporter'

import {
  additionalCostText,
  expensiveServiceText,
  isManualInputService,
  renderPlanGroup,
  renderSelectProcessPole,
} from './renderFunctions'
import { typeResultMoveToForcedValue } from './planForcedData'

import Container from './styles'

// const reprocessInfoText = 'Quando esta opção for checada, caso haja algum erro durante o processo de análise, o documento será reprocessado automaticamente.'

// Creates an object with the number of selected services of each group
const initializeGroups = (services) => {
  if (_.isEmpty(services)) return {}

  const data = {}

  _.each(_.keys(GROUP_ID), (groupId) => {
    data[groupId] = _.countBy(services, (service) => service.group_id === groupId && service.checked).true || 0
  })

  return data
}

export default function ({ docType, orgId = null, readOnly = false }) {
  const dispatch = useDispatch()
  const apiKey = useSelector((state) => state.auth.api_key)
  const userId = useSelector((state) => state.auth.sub)

  let servicesList = null
  switch (docType) {
    case PANEL_ENVS.CPF:
      servicesList = useSelector((state) => state.planCpfServices.services)
      break
    case PANEL_ENVS.CNPJ:
      servicesList = useSelector((state) => state.planCnpjServices.services)
      break
    default:
  }

  const [planIdCurrent, setPlanIdCurrent] = useState(null)
  const [planServicesCurrent, setPlanServicesCurrent] = useState([])
  const [processPole, setProcessPole] = useState(POLE_TYPE_TO_CONSULT.PASSIVE)

  const [loading, setLoading] = useState(true)
  const [services, setServices] = useState([])
  const [groupsSelected, setGroupsSelected] = useState({})

  const initializeScoreService = (newServicesList) => {
    const service = _.find(newServicesList, (s) => (s.service_id.includes('credit_score')))
    if (_.isNil(service)) return

    _.each(service.type_results, typeResult => {
      if (_.isNil(typeResult.data)) return

      _.each(typeResult.data, (v, _k) => {
        if (!_.isNil(v.value)) return

        v.value = 300
      })
    })
  }

  const initializeProcessClassifier = (newServicesList) => {
    const service = _.find(newServicesList, { service_id: 'processes_classifier' })
    if (_.isNil(service)) return

    _.each(service.type_results, typeResult => {
      if (_.isNil(typeResult.data)) return

      const defaultMoveTo = typeResult.move_to
      typeResult.move_to = typeResultMoveToForcedValue(
        service.service_id,
        typeResult.type_result_id,
        defaultMoveTo,
      )

      _.each(typeResult.data, (v, _k) => {
        if (!_.isNil(v.values)) return

        v.values = []
      })
    })
  }

  const initializeMembershipBoardRecursive = (newServicesList) => {
    const service = _.find(newServicesList, { service_id: 'membership_board_recursive' })
    if (_.isNil(service)) return

    _.each(service.type_results, typeResult => {
      if (_.isNil(typeResult.data)) return

      _.each(typeResult.data, (v, _k) => {
        if (!_.isNil(v.value)) return

        v.value = 0
      })
    })
  }

  const getAndSetPlan = async () => {
    let res = null

    try {
      res = await getLastGeneratedPlan(apiKey, userId, docType, orgId)
    } catch (err) {
      reportError(err, 'Erro ao obter plano')
      return
    }

    setPlanIdCurrent(res.data.plan_id)
    setPlanServicesCurrent(res.data.services)
    setProcessPole(res.data.process_pole)
  }

  useEffect(() => {
    switch (docType) {
      case PANEL_ENVS.CPF:
        dispatch(fetchPlanCpfServicesList())
        break
      case PANEL_ENVS.CNPJ:
        dispatch(fetchPlanCnpjServicesList())
        break
      default:
    }

    getAndSetPlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (_.isEmpty(servicesList) || _.isEmpty(planServicesCurrent)) return

    const newServicesList = _.cloneDeep(servicesList)

    // initialize fields
    _.each(newServicesList, (service) => {
      service.checked = false
      _.each(service.type_results, typeResult => {
        typeResult.move_to = analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.reproved
      })
    })

    // add default values [] to processes_classifier
    initializeProcessClassifier(newServicesList)

    _.each(planServicesCurrent, (serviceCurr) => {
      const service = _.find(newServicesList, { service_id: serviceCurr.service_id })
      if (_.isEmpty(service)) return

      service.checked = true

      _.each(serviceCurr.type_results, typeResultCurr => {
        const typeResult = _.find(
          service.type_results,
          { type_result_id: typeResultCurr.type_result_id },
        )

        typeResult.move_to = typeResultMoveToForcedValue(service.service_id, typeResult.type_result_id, typeResultCurr.move_to)

        if (!_.isNil(typeResult.data)) {
          typeResult.data = _.merge(typeResultCurr.data, typeResult.data)
        }
      })
    })

    // add default value (300) to score services
    initializeScoreService(newServicesList)

    // add default value (0) to max_depth
    initializeMembershipBoardRecursive(newServicesList)

    setServices(newServicesList)
    setGroupsSelected(initializeGroups(newServicesList))
    setLoading(false)
  }, [planServicesCurrent, servicesList])

  const handleChangeSelectProcessPole = (event) => {
    setProcessPole(event.target.value)
  }

  const onUpdateTypeResults = (serviceId, typeResults) => {
    const newServices = _.cloneDeep(services)
    const serviceIndex = _.findIndex(newServices, { service_id: serviceId })

    _.each(typeResults, (newTypeResult) => {
      const serviceTypeResult = _
      .chain(newServices)
      .get(`${serviceIndex}.type_results`)
      .find(typeRes => typeRes.type_result_id === newTypeResult.type_result_id)
      .value()

      serviceTypeResult.move_to = newTypeResult.move_to

      _.each(newTypeResult.data, (item, itemName) => {
        _.set(serviceTypeResult, `data.${itemName}.values`, [...item.values])
        serviceTypeResult.data = { ...serviceTypeResult.data }
      })

    })

    setServices([...newServices])
  }

  const handleClickRadio = (serviceId, typeResultId, moveTo) => {
    const newServices = _.cloneDeep(services)
    const serviceIndex = _.findIndex(newServices, { service_id: serviceId })

    const typeResult = _
      .chain(newServices)
      .get(`${serviceIndex}.type_results`)
      .find(typeRes => typeRes.type_result_id === typeResultId)
      .value()

    typeResult.move_to = moveTo

    setServices(newServices)
  }

  const handleChangeChips = (chips, serviceId, typeResultId, fieldData) => {
    const newServices = _.cloneDeep(services)
    const serviceIndex = _.findIndex(newServices, { service_id: serviceId })

    const typeResult = _
      .chain(newServices)
      .get(`${serviceIndex}.type_results`)
      .find(typeRes => typeRes.type_result_id === typeResultId)
      .value()

    _.set(typeResult, `data.${fieldData}.values`, chips)

    setServices(newServices)
  }

  const handleChangeSlider = (sliderValue, serviceId, typeResultId, fieldData) => {
    const newServices = _.cloneDeep(services)
    const serviceIndex = _.findIndex(newServices, { service_id: serviceId })

    const typeResult = _
      .chain(newServices)
      .get(`${serviceIndex}.type_results`)
      .find(typeRes => typeRes.type_result_id === typeResultId)
      .value()

    _.set(typeResult, `data.${fieldData}.value`, sliderValue)

    setServices(newServices)
  }

  const handleCheckService = (serviceId, checked) => {
    const newServices = _.cloneDeep(services)
    const service = _.find(newServices, { service_id: serviceId })

    if (checked === true && service.additional_cost)
      if (!window.confirm(additionalCostText(service.additional_cost, true)))
        return

    if (checked === true && service.expensive_service)
      if (!window.confirm(expensiveServiceText(true)))
        return

    service.checked = checked

    setServices([...newServices])

    const groups = _.cloneDeep(groupsSelected)

    groups[service.group_id] += checked ? 1 : -1
    setGroupsSelected(groups)
  }

  const handleCheckServiceGroup = (groupId, checked) => {
    let servicesSelected = 0
    const newServices = _.map(services, (service) => {
      if (service.group_id !== groupId) return service
      if (
        checked &&
        (
          service.additional_cost ||
          service.expensive_service ||
          service.gov_br_auth_required ||
          isManualInputService(service)
        )
      ) return service

      service.checked = checked

      if (checked) servicesSelected += 1

      return service
    })

    setServices([...newServices])

    const groups = _.cloneDeep(groupsSelected)
    groups[groupId] = servicesSelected
    setGroupsSelected(groups)
  }

  const handleSave = async () => {
    const servicesChecked = _.filter(services, { checked: true })

    if (servicesChecked.length < 1) {
      toast.error('Selecione ao menos uma opção!')
      return
    }

    let res = null
    try {
      res = await postGeneratePlan(
        apiKey,
        userId,
        docType,
        servicesChecked,
        processPole,
        orgId,
      )
    } catch (err) {
      reportError(err, 'Erro ao criar plano')
      return
    }

    setPlanIdCurrent(res.data.plan_id)
    toast.success('Plano atualizado com sucesso.')
  }

  if (loading) {
    return (
      <CircularProgress />
    )
  }

  const renderReadOnlyMsg = () => {
    if (!readOnly) return null

    return (
      <div style={{ marginBottom: 10 }}>
        <Alert severity="warning">
          <AlertTitle>Aviso</AlertTitle>
          Você não tem permissão para alterar o plano!
        </Alert>
      </div>
    )
  }

  const groups = _.keys(GROUP_ID)

  return (
    <Container>
      {renderReadOnlyMsg()}

      {renderSelectProcessPole(processPole, handleChangeSelectProcessPole, readOnly)}

      <div className="label-checkboxes">Selecione os itens que irão setar o status de uma pesquisa automaticamente, caso haja alguma inconformidade na análise:</div>

      {_.map(
        groups,
        (gId) => renderPlanGroup(
          gId,
          services,
          servicesList,
          docType,
          groupsSelected,
          handleCheckServiceGroup,
          handleChangeChips,
          handleChangeSlider,
          handleClickRadio,
          handleCheckService,
          onUpdateTypeResults,
          readOnly,
        ),
      )}

      <div className="footer-container">
        <div className="plan-id-container">
          <span>
            Seu plan_id é:
            {' '}
            <strong>{planIdCurrent}</strong>
          </span>
        </div>

        <div className="button-container">
          <Button
            onClick={handleSave}
            type="submit"
            className="primary button-save"
            disabled={readOnly}
          >
            Salvar
          </Button>
        </div>
      </div>
    </Container>
  )
}
