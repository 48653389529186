import styled from 'styled-components'

export default styled.div`
  .label-checkboxes {
    margin-bottom: 15px;
    font-size: 15px;
  }

  .button-save {
    width: 300px;
  }

  .table {
    thead {
      tr {
        th {
          border-top: none;
          padding: 10px 35px;
          border-bottom: 2px solid ${(props) => props.theme.palette.gray[300]};
        }

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    tbody {
      border-left: none;
      border-right: none;

      tr {
        border-bottom: none;

        &:last-child {
          border-bottom: none;
        }

        td {
          padding: 1.5rem;
          font-size: 1.3rem;
        }

        .no-center{
          text-align: left;
        }

        .subitem-left {
          padding-left: 3rem !important;
        }

        .subitem-right {
          padding-right: 3rem !important;
        }
      }
    }
  }

  .input-select-process-pole {
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .head-with-icon {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }

  .footer-container {
    display: flex;
    align-items: center;

    .plan-id-container {
      padding: 1rem;
      background-color: #fbffbd;
      border-radius: 5px;
      position: absolute;
    }

    .button-container {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .container-chip-input {
    width: 350px;
    margin-left: 2rem;
  }
`
