import React from 'react'
import _ from 'lodash'
import { formatToBRL } from 'brazilian-values'

import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined'
import CreateIcon from '@material-ui/icons/Create'
import { InputLabel, MenuItem, Select, Slider } from '@material-ui/core'

import { Checkbox, Icons, Radio, Table } from 'ui/components'
import LightTooltip from 'ui/components/ContainerCommonPanels/LightTooltip'
import PlanAccordion from 'ui/components/ContainerCommonPanels/PlanAccordion'

import analysisStatusConstants from 'utils/constants/analysisStatus'
import { POLE_TYPE_TO_CONSULT } from 'utils/constants/poleTypes'

import {
  additionalCostGroupText,
  govBrRequiredServicesText,
  govBrRequiredServiceText,
  manualInputGroupText,
  manualInputServiceText,
} from 'utils/constants/planSpecialServices'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import CounterButton from 'ui/components/CounterButton'
import MuiChipInput from 'ui/components/MuiChipInput'
import { nameGroupId } from 'utils/constants/groupService'

import SaveLoadProcessesClassifierPreset from './ProcessesClassifierPreset/SaveLoadProcessesClassifierPreset'
import getPlanServiceHelp from './planServiceHelp'
import {
  typeResultMoveToDisabledOptions,
  typeResultMoveToForceDisabled,
  typeResultMoveToForcedValue,
} from './planForcedData'

const IconTooltip = React.forwardRef(function IconTooltip(props, ref) {
  return <div {...props} ref={ref}><Icons icon="helpFilled" color="#424242" /></div>
})

const blacklistInfoText = (docType) => `Essa opção marcará a consulta como REPROVADA e o ${docType.toUpperCase()} será enviado para a lista de bloqueados.`

const isManualInputService = (service) => (
  service.service_id === 'processes_classifier' || service.service_id.includes('credit_score')
)

const confirmationMsg = (showConfirm) => (
  showConfirm ? ' Deseja prosseguir adicionando-o ao plano?' : ''
)

const additionalCostText = (cost, showConfirm = false) => {
  return `A execução deste serviço incorrerá em uma taxa adicional de ${formatToBRL(cost)} por consulta.${confirmationMsg(showConfirm)}`
}

const expensiveServiceText = (showConfirm = false) => {
  return `A execução deste serviço poderá ter um custo elevado, além do normal.${confirmationMsg(showConfirm)}`
}

const filterServicesByGroupId = (groupId, services, servicesList) => {
  const servicesIdsPermitted = _
    .chain(servicesList)
    .filter({ group_id: groupId })
    .map((serv) => serv.service_id)
    .value()

  return _.filter(services, (service) => servicesIdsPermitted.includes(service.service_id))
}

const renderRadioMoveTo = (
  serviceId,
  typeResultId,
  services,
  handleClickRadio,
  forceDisabled = false,
  forcedValue = null,
  disbledOptions = [],
) => {
  const service = _.find(services, { service_id: serviceId })
  const typeResult = service.type_results.find(typeRes => typeRes.type_result_id === typeResultId)
  let isRadioActived = false

  return _
    .chain(analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO)
    .values()
    .map(moveTo => {
      const isNotSelectable = disbledOptions.includes(moveTo)
      const radioValue = isRadioActived || isNotSelectable ? false : typeResult.move_to === moveTo || forcedValue === moveTo
      isRadioActived = radioValue || isRadioActived

      return (
        <td key={moveTo}>
          <Radio
            value={radioValue}
            disabled={!service.checked || forceDisabled || isNotSelectable}
            onClick={() => handleClickRadio(serviceId, typeResultId, moveTo)}
          />
        </td>
      )
    })
    .value()
}

const renderObjectData = (
  serviceId,
  typeResultId,
  services,
  handleChangeChips,
  handleChangeSlider,
) => {
  const service = _.find(services, { service_id: serviceId })
  const typeResult = service.type_results.find(
    (typeRes) => typeRes.type_result_id === typeResultId,
  )
  const ignoredTypeResultItems = [
    'journal_blacklist_exact',
    'journal_whitelist_exact',
    'process_type_blacklist_exact',
    'process_type_whitelist_exact',
  ]

  const renderTypeResultName = (showName, data) => {
    if (_.isEmpty(showName)) return null
    if (_.isEmpty(data.name)) return null

    return (
      <div>
        {data.name}
        {!_.isEmpty(data.description) && (
          <LightTooltip title={data.description}>
            <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>
              <Icons icon="helpFilled" color="#424242" />
            </span>
          </LightTooltip>
        )}
      </div>
    )
  }

  const renderChips = (key, value) => {
    return (
      <MuiChipInput
        value={value.values}
        disabled={!service.checked}
        fullWidth={true}
        fullWidthInput={true}
        label={value.name}
        onChange={(_e, chips) => handleChangeChips([...new Set(chips)], serviceId, typeResultId, key)}
        onPaste={(e) => {
          const newValues = [...value.values, ...e.clipboardData.getData('Text')?.split('\n')]
          handleChangeChips([...new Set(newValues)], serviceId, typeResultId, key)
        }}
      />
    )
  }

  const renderSlider = (key, value, showName = true) => {
    const scoreVal = value.value || 0

    return (
      <React.Fragment>
        {renderTypeResultName(showName, value)}

        <Slider
          key={`planslider${Date.now()}`}
          disabled={!service.checked}
          valueLabelDisplay="auto"
          min={value.min_value}
          max={value.max_value}
          step={5}
          size='medium'
          marks={
            [
              { label: 'Muito alto', value: 300 },
              { label: 'Alto', value: 553 },
              { label: 'Médio', value: 725 },
              { label: '', value: 874 },
              { label: 'Muito baixo', value: 937 },
              { label: '', value: 1000 },
            ]
          }
          defaultValue={scoreVal}
          onChangeCommitted={(_slider, val) => handleChangeSlider(val, serviceId, typeResultId, key)}
        />
      </React.Fragment>
    )
  }

  const renderMaxDropDownCounter = (key, value) => {
    const counterValue = value.value || 0

    return (
      <div>
        {renderTypeResultName(true, value)}

        <CounterButton
          disabled={!service.checked || counterValue === 0}
          max={value.max_value}
          min={1}
          value={counterValue}
          onChange={(v) =>
            counterValue === 0
              ? null
              : handleChangeSlider(v, serviceId, typeResultId, key)}
        />

        <br />
        <br />

        <Checkbox
          label="Buscar indefinidamente por todos os membros e submembros."
          value={counterValue === 0}
          disabled={!service.checked}
          style={{ marginLeft: 30 }}
          onClick={() => handleChangeSlider(
            counterValue === 0 ? 1 : 0,
            serviceId,
            typeResultId,
            key,
          )}
        />
      </div>
    )
  }

  const filterTypeResultData = _.pickBy(typeResult.data, (_v, k) => (
    !ignoredTypeResultItems.includes(k)
  ))

  return (
    <React.Fragment>
      {_.map(filterTypeResultData, (v, k) => (
        <div key={k.toString()} className="container-chip-input">
          <LightTooltip arrow title={getPlanServiceHelp(serviceId, k) || ''}>
            <span>
              {(v.type === 'TEXT' || v.type === 'ARRAY_TEXT') && renderChips(k, v)}
              {v.type === 'NUMBER_RANGE' && renderSlider(k, v)}
              {v.type === 'NUMBER_SELECT' && renderMaxDropDownCounter(k, v)}
            </span>
          </LightTooltip>
        </div>
      ))}
    </React.Fragment>
  )
}

const renderSaveLoadPresets = (service, onUpdateTypeResults) => {
  if (service.service_id !== 'processes_classifier') return null

  return (
    <tr>
      <td>
        <SaveLoadProcessesClassifierPreset
          service={service}
          onUpdateTypeResults={onUpdateTypeResults}
        />
      </td>
    </tr>
  )
}

const renderTypeResult = (serviceId, typeResult, services, handleChangeChips, handleChangeSlider, handleClickRadio) => {
  switch (typeResult.type) {
    case 'ENUM':
      return renderRadioMoveTo(serviceId, typeResult.type_result_id, services, handleClickRadio)
    case 'OBJECT':
      return renderObjectData(serviceId, typeResult.type_result_id, services, handleChangeChips, handleChangeSlider)
    default:
      return null
  }
}

const renderTypeResultComplex = (serviceId, typeResult, services, handleChangeChips, handleChangeSlider, handleClickRadio) => {
  const hideTypeResultName = () => {
    const servicesToHideName = [
      'credit_score_cnpj',
      'credit_score_cpf',
      'membership_board_recursive',
    ]

    return !_.includes(servicesToHideName, serviceId)
  }

  switch (typeResult.type) {
    case 'ENUM':
      return (
        <React.Fragment>
          <td>
            {typeResult.name}
          </td>
          {/* <td /> */}
          {renderRadioMoveTo(serviceId, typeResult.type_result_id, services, handleClickRadio)}
        </React.Fragment>
      )
    case 'OBJECT':
      return (
        <React.Fragment>
          <td>
            {hideTypeResultName(serviceId) && typeResult.name}
            {renderObjectData(serviceId, typeResult.type_result_id, services, handleChangeChips, handleChangeSlider)}
          </td>
          {/* <td /> */}
          {renderRadioMoveTo(
            serviceId,
            typeResult.type_result_id,
            services,
            handleClickRadio,
            typeResultMoveToForceDisabled(serviceId, typeResult.type_result_id),
            typeResultMoveToForcedValue(serviceId, typeResult.type_result_id),
            typeResultMoveToDisabledOptions(serviceId, typeResult.type_result_id),
          )}
        </React.Fragment>
      )
    default:
      return null
  }
}

const renderAdditionalCostTooltip = (title) => (
  <span>
    <LightTooltip placement='right' title={title} style={{ marginLeft: 5 }}>
      <AttachMoneyOutlinedIcon style={{ verticalAlign: 'middle',  fontSize: '2.5rem' }} color="primary" />
    </LightTooltip>
  </span>
)

const renderGovBrRequiredTooltip = (title) => (
  <span>
    <LightTooltip placement='right' title={title}>
      <AssignmentIndIcon style={{ marginLeft: 5, marginBottom: 3, verticalAlign: 'middle',  fontSize: '2.4rem' }} color="primary" />
    </LightTooltip>
  </span>
)

const renderManualInputTooltip = (title) => (
  <span style={{ marginLeft: 3 }}>
    <LightTooltip placement='right' title={title}>
      <CreateIcon style={{ verticalAlign: 'middle', fontSize: '2.1rem' }} color="primary" />
    </LightTooltip>
  </span>
)

const renderServiceLabel = (service) => {
  return (
    <React.Fragment>
      <span>{service.name}</span>
      {service.additional_cost && renderAdditionalCostTooltip(additionalCostText(service.additional_cost))}
      {service.expensive_service && renderAdditionalCostTooltip(expensiveServiceText())}
      {service.gov_br_auth_required && renderGovBrRequiredTooltip(govBrRequiredServiceText)}
      {isManualInputService(service) && renderManualInputTooltip(manualInputServiceText)}
    </React.Fragment>
  )
}

const renderListServices = (
  groupServices,
  services,
  handleChangeChips,
  handleChangeSlider,
  handleClickRadio,
  handleCheckService,
  onUpdateTypeResults,
  readOnly,
) => {
  // Simple Type Result Services: those with only one TR which is the default TR
  const isSimpleTypeResultService = (service) => (
    _.size(service.type_results) === 1 && service.type_results[0].type_result_id === 'DEFAULT'
  )

  // Complex Type Result Services: those with more than one TR and/or with at least one non default TR
  const isComplexTypeResultService = (service) => (
    _.size(service.type_results) > 1 || _.some(service.type_results, (tr) => (tr.type_result_id !== 'DEFAULT'))
  )

  return _.map(groupServices, (service) => (
    <React.Fragment key={service.service_id}>
      <tr>
        <td className="no-center">
          <Checkbox
            disabled={readOnly}
            value={service.checked}
            label={renderServiceLabel(service)}
            onClick={(checked) => handleCheckService(service.service_id, checked)}
          />
        </td>

        {/* TODO: desativado pois ainda nao existe a funcionalidade */}
        {/* <td> */}
        {/*   <Checkbox disabled={!service.checked} /> */}
        {/* </td> */}
        {
          isSimpleTypeResultService(service) &&
          renderTypeResult(service.service_id, service.type_results[0], services, handleChangeChips, handleChangeSlider, handleClickRadio)
        }
      </tr>
      {
        isComplexTypeResultService(service) && (
          <React.Fragment>
            {renderSaveLoadPresets(service, onUpdateTypeResults)}
            {_.map(service.type_results, (typeResult, i) => (
              <tr key={i.toString()}>
                {renderTypeResultComplex(service.service_id, typeResult, services, handleChangeChips, handleChangeSlider, handleClickRadio)}
              </tr>
            ))}
          </React.Fragment>
        )
      }
    </React.Fragment>
  ))
}

const renderSelectProcessPole = (processPole, handleChangeSelectProcessPole, disabled = false) => {
  return (
    <React.Fragment>
      <InputLabel>
        Selecione o polo a ser consultado nos serviços de busca processual:
      </InputLabel>

      <Select
        className="input-select-process-pole"
        value={processPole}
        label="Tipo de Polo"
        onChange={handleChangeSelectProcessPole}
        disabled={disabled}
      >
        <MenuItem value={POLE_TYPE_TO_CONSULT.PASSIVE}>Polo passivo</MenuItem>
        <MenuItem value={POLE_TYPE_TO_CONSULT.ACTIVE}>Polo ativo</MenuItem>
        <MenuItem value={POLE_TYPE_TO_CONSULT.ALL}>Todos os polos</MenuItem>
      </Select>
    </React.Fragment>
  )
}

const renderPlanGroup = (
  groupId,
  services,
  servicesList,
  docType,
  groupsSelected,
  handleCheckServiceGroup,
  handleChangeChips,
  handleChangeSlider,
  handleClickRadio,
  handleCheckService,
  onUpdateTypeResults,
  readOnly = false,
) => {
  const groupServices = filterServicesByGroupId(groupId, services, servicesList)
  if (_.isEmpty(groupServices)) return null

  const hasAdditionalCostService = _.some(groupServices, (g) => !_.isNil(g.additional_cost) || g.expensive_service)
  const hasGovBrRequiredService = _.some(groupServices, (g) => g.gov_br_auth_required === true)
  const hasManualInputService = _.some(groupServices, (g) => isManualInputService(g))
  const title = (
    <span>
      {nameGroupId(groupId)}
      {hasAdditionalCostService && renderAdditionalCostTooltip(additionalCostGroupText)}
      {hasGovBrRequiredService && renderGovBrRequiredTooltip(govBrRequiredServicesText)}
      {hasManualInputService && renderManualInputTooltip(manualInputGroupText)}
    </span>
  )

  return (
    <div key={`tr${docType}${groupId}`}>
      <PlanAccordion
        title={title}
        servicesSelected={groupsSelected[groupId]}
        servicesLength={groupServices.length}
        hasAdditionalCostService={hasAdditionalCostService}
        hasManualInputService={hasManualInputService}
        hasGovBrRequiredService={hasGovBrRequiredService}
        checkboxDisabled={readOnly}
        onClickCheckbox={(e) => handleCheckServiceGroup(groupId, e.target.checked)}
      >
        <Table style={{ marginTop: 15, marginBottom: 15, marginLeft: 40 }} className="table">
          <thead>
            <tr>
              <th>Serviço</th>
              {/* TODO: desativado pois ainda nao existe a funcionalidade */}
              {/* <th> */}
              {/*   <span className="head-with-icon"> */}
              {/*     <span>Reprocessar</span> */}
              {/*     <LightTooltip title={reprocessInfoText} placement="right"> */}
              {/*       <IconTooltip /> */}
              {/*     </LightTooltip> */}
              {/*   </span> */}
              {/* </th> */}
              <th>Aprovado</th>
              <th>Em Atenção</th>
              <th>Reprovado</th>
              <th>
                <span className="head-with-icon">
                  <span>Bloqueado</span>
                  <LightTooltip title={blacklistInfoText(docType)} placement="right">
                    <IconTooltip />
                  </LightTooltip>
                </span>
              </th>
            </tr>
          </thead>

          <tbody>
            {renderListServices(
              groupServices,
              services,
              handleChangeChips,
              handleChangeSlider,
              handleClickRadio,
              handleCheckService,
              onUpdateTypeResults,
              readOnly,
            )}
          </tbody>
        </Table>
      </PlanAccordion>
    </div>
  )
}

export {
  additionalCostText,
  expensiveServiceText,
  isManualInputService,
  renderPlanGroup,
  renderSelectProcessPole,
}
