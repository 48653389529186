import React from 'react'

import BaseConsultsList from 'ui/views/consults/BaseConsultsList'
import analysisStatusConstants from 'utils/constants/analysisStatus'
import { MANUAL_DERIVATION_STATUS } from 'utils/constants/manualDerivationStatus'

export default function ({ location }) {
  const title = 'Finalizadas - Reprovado'
  const color = '#19D315'
  const analysisStatus = analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved
  const manualDerivationStatus = MANUAL_DERIVATION_STATUS.FINALIZED
  const withManualAnalysis = true
  const itemWithManualAnalysis = true

  return (
    <BaseConsultsList
      color={color}
      location={location}
      title={title}
      analysisStatus={analysisStatus}
      manualDerivationStatus={manualDerivationStatus}
      withManualAnalysis={withManualAnalysis}
      itemWithManualAnalysis={itemWithManualAnalysis}
    />
  )
}
