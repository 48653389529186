import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { ButtonRecaptcha } from 'ui/components'

import { successLogin, changePassword } from 'redux/auth/actions'
import { login } from 'services/apiAuth'
import { isNetworkError, isUserLogged } from 'utils/functions'

import styles from './styles'

const Login = () => {
  const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()

  const accessToken = useSelector(state => state.auth.access_token)
  const panelEnv = useSelector(state => state.auth.panelEnv)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (isUserLogged(accessToken)) {
      history.push(`/${panelEnv}/processing`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const handleSubmit = async (argEmail, argPassword, recaptchaToken) => {
    try {
      const formData = new FormData()
      formData.append('username', argEmail)
      formData.append('password', argPassword)
      formData.append('app_id', process.env.REACT_APP_COGNITO_APP_ID)
      formData.append('user_pool', process.env.REACT_APP_COGNITO_USER_POOL)
      formData.append('recaptcha_token', recaptchaToken)

      const res = await login(formData)
      if (res.status === 302) {
        dispatch(changePassword({
          email: argEmail,
          changePasswordSession: res.data.session,
        }))

        return history.push('/password-change')
      }

      const userData = res.data
      if (userData) {
        dispatch(successLogin({ ...userData }))
      }

      return false
    } catch (err) {
      if (!isNetworkError(err)) {
        const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao fazer login'
        toast.error(messageError)
      }

      return null
    }
  }

  return (
    <div className={classes.root}>
      <aside className={classes.aside}>
        <h1>
          <img src="/assets/images/logos/logo.svg" alt="Logo" className={classes.logo} />
        </h1>
      </aside>
      <main className={classes.main}>
        <div className={classes.main}>
          <h1 className={classes.title}>Login</h1>
          <div className={classes.inputsContainer}>
            <form className={classes.formContainer}>
              <TextField
                id="email"
                label="E-mail"
                className={classes.textField}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required={true}
                autoCapitalize="off"
                autoCorrect="off"
              />
              <TextField
                id="password"
                label="Senha"
                className={classes.textField}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                required={true}
                autoCapitalize="off"
                autoCorrect="off"
              />
            </form>
            <Typography>
              <Link to="/password-recover">Esqueci a senha</Link>
            </Typography>
          </div>

          <ButtonRecaptcha
            text="Acessar"
            onClick={(token) => handleSubmit(email, password, token)}
          />
        </div>
      </main>
    </div>
  )
}


export default withStyles(styles, { withTheme: true })(Login)
