import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Badge as MUIBadge } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { Icons } from 'ui/components'
import { getList } from 'services/apiMotorBgc'
import { MANUAL_DERIVATION_STATUS } from 'utils/constants/manualDerivationStatus'
import analysisStatusConstants from 'utils/constants/analysisStatus'
import { translateRole, USER_ROLE } from 'utils/constants/user'

const Badge = withStyles({
  anchorOriginTopRightRectangle: {
    backgroundColor: '#ccc',
    right: -30,
    transform: 'scale(1) translate(50%, -10%)',
  },
})(MUIBadge)

const BADGE_MAX = 999999999

export default function ({ currentPath }) {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const [totalInQueueAnalyst, setTotalInQueueAnalyst] = useState(null)
  const [totalInQueueSupervisor, setTotalInQueueSupervisor] = useState(null)
  const [totalInAnalysis, setTotalInAnalysis] = useState(null)
  const [totalFinalizedApproved, setTotalFinalizedApproved] = useState(null)
  const [totalFinalizedReproved, setTotalFinalizedReproved] = useState(null)

  const listDocs = async (type, options = {}) => {
    let res = null
    try {
      res = await getList(
        apiKey,
        userId,
        panelEnv,
        {
          analysisStatus: options.analysisStatus,
          manualDerivationStatus: options.manualDerivationStatus,
          manualDerivationToRole: options.manualDerivationToRole,
        },
      )
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao listar CPFs'
      toast.error(messageError)
      return
    }

    switch (type) {
      case 'inQueueAnalyst': setTotalInQueueAnalyst(parseInt(res.headers['x-total'], 10)); break
      case 'inQueueSupervisor': setTotalInQueueSupervisor(parseInt(res.headers['x-total'], 10)); break
      case 'inAnalysis': setTotalInAnalysis(parseInt(res.headers['x-total'], 10)); break
      case 'finalizedApproved': setTotalFinalizedApproved(parseInt(res.headers['x-total'], 10)); break
      case 'finalizedReproved': setTotalFinalizedReproved(parseInt(res.headers['x-total'], 10)); break
      default:
        break
    }
  }

  const calculateTotals = () => {
    listDocs('inQueueAnalyst', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.IN_QUEUE,
      manualDerivationToRole: USER_ROLE.ANALYST,
    })
    listDocs('inQueueSupervisor', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.IN_QUEUE,
      manualDerivationToRole: USER_ROLE.SUPERVISOR,
    })
    listDocs('inAnalysis', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.IN_ANALYSIS,
    })
    listDocs('finalizedApproved', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.FINALIZED,
      analysisStatus: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved,
    })
    listDocs('finalizedReproved', {
      manualDerivationStatus: MANUAL_DERIVATION_STATUS.FINALIZED,
      analysisStatus: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved,
    })
  }

  useEffect(() => {
    setTimeout(calculateTotals, 3 * 1000)

    const intervalId = setInterval(calculateTotals, 120 * 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderNavManualAnalysis = () => {
    return (
      <li>
        <div className="category">
          <Icons icon="fileText" />
          Analises Manuais
        </div>

        <ul>
          <li>
            <Link
              className={(currentPath === `/${panelEnv}/manual-analysis/in-queue-analyst`) ? 'selected' : ''}
              to={`/${panelEnv}/manual-analysis/in-queue-analyst`}
            >
              <Badge badgeContent={totalInQueueAnalyst} color="primary" max={BADGE_MAX}>
                {`Na Fila - ${translateRole(USER_ROLE.ANALYST)}`}
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/manual-analysis/in-queue-supervisor`) ? 'selected' : ''}
              to={`/${panelEnv}/manual-analysis/in-queue-supervisor`}
            >
              <Badge badgeContent={totalInQueueSupervisor} color="primary" max={BADGE_MAX}>
                {`Na Fila - ${translateRole(USER_ROLE.SUPERVISOR)}`}
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/manual-analysis/in-analysis`) ? 'selected' : ''}
              to={`/${panelEnv}/manual-analysis/in-analysis`}
            >
              <Badge badgeContent={totalInAnalysis} color="primary" max={BADGE_MAX}>
                Em analise
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/manual-analysis/finalized-approved`) ? 'selected' : ''}
              to={`/${panelEnv}/manual-analysis/finalized-approved`}
            >
              <Badge badgeContent={totalFinalizedApproved} color="primary" max={BADGE_MAX}>
                Finalizadas - Aprovado
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/manual-analysis/finalized-reproved`) ? 'selected' : ''}
              to={`/${panelEnv}/manual-analysis/finalized-reproved`}
            >
              <Badge badgeContent={totalFinalizedReproved} color="primary" max={BADGE_MAX}>
                Finalizadas - Reprovado
              </Badge>
            </Link>
          </li>
        </ul>
      </li>
    )
  }

  return (
    <nav>
      <ul>
      {renderNavManualAnalysis()}
      </ul>
    </nav>
  )
}
