import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  type: {
    fontSize: 35,
    color: theme.palette.primary.grayLight2,
  },
  title: {
    fontSize: 30,
    color: theme.palette.primary.gray,
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.primary.gray,
  },
}))

export default styles
