import React, { Fragment } from 'react'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { formatToCNPJ, formatToCPF } from 'brazilian-values'
import TimeCounter from 'ui/components/ContainerCommonPanels/TimeCounter'

export const columnsPayments = [
  {
    name: 'number',
    label: 'Número da Parcela',
  },
  {
    name: 'situation',
    label: 'Situação',
  },
  {
    name: 'available_from',
    label: 'Disponível em',
  },
]

export const columnsSocial = [
  {
    name: 'program_name',
    label: 'Nome do Programa',
  },
  {
    name: 'program_state',
    label: 'Estado',
  },
  {
    name: 'assistance_details_city',
    label: 'Cidade',
  },
  {
    name: 'assistance_start_date',
    label: 'Início',
  },
  {
    name: 'assistance_amount',
    label: 'Valor',
  },
]

export const columnsRais = [
  {
    name: 'cnpj_cei',
    label: 'CNPJ',
    options: {
      customBodyRender: (value) => {
        try {
          return value ? formatToCNPJ(value) : null
        } catch (err) {
          Sentry.captureException(err)
          return false
        }
      },
    },
  },
  {
    name: 'corporate_name',
    label: 'Nome',
  },
  {
    name: 'situation',
    label: 'Situação',
  },
  {
    name: 'admission_date',
    label: 'Data de Admissão',
  },
  {
    name: 'delivery_date',
    label: 'Data de entrega',
  },
  {
    name: 'shutdown_date',
    label: 'Data de Conclusão',
  },
]

export const columnsRelatives = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'cpf',
    label: 'CPF',
    options: {
      customBodyRender: (value) => {
        try {
          return value ? formatToCPF(value) : null
        } catch (err) {
          Sentry.captureException(err)
          return false
        }
      },
    },
  },
  {
    name: 'birth_date',
    label: 'Data de Nascimento',
  },
]

export const columnsPartners = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'cnpj',
    label: 'CNPJ',
    options: {
      customBodyRender: (value) => {
        try {
          return value ? <div style={{ width: 100 }}>{formatToCNPJ(value)}</div> : null
        } catch (err) {
          Sentry.captureException(err)
          return null
        }
      },
    },
  },
  {
    name: 'cadastral_situation',
    label: 'Situação',
    options: {
      customBodyRender: (value) => {
        if (!Number.isNaN(Number(value))) {
          return <TimeCounter responseTime={value} column={true} small={true} />
        }

        return <strong>{value}</strong>
      },
    },
  },
  {
    name: 'members',
    label: 'Sócios',
    options: {
      customBodyRender: (members) => {
        if (_.isEmpty(members)) return null

        if (!Number.isNaN(Number(members))) {
          return <TimeCounter responseTime={members} column={true} small={true} />
        }

        const textMember = (member) => {
          const name = _.chain(member).get('name').toLower().startCase().value()
          const cpf = formatToCPF(_.get(member, 'cpf') || '')
          const qualification = _.chain(member).get('qualification').replace(/[0-9]+?-/, '').toLower().startCase().value() || ''

          return _.filter([name, cpf, qualification], (x) => !_.isEmpty(x)).join(' - ')
        }

        return (
          <Fragment>
            {
              _.map(members, (member, i) => (
                <Fragment key={i.toString()}>
                  <p style={{ whiteSpace: 'nowrap' }}>
                    {textMember(member)}
                  </p>
                </Fragment>
              ))
            }
          </Fragment>
        )
      },
    },
  },
  {
    name: 'type',
    label: 'Tipo',
  },
  {
    name: 'address',
    label: 'Endereço',
  },
  // {
  //   name: 'neighborhood',
  //   label: 'Bairro',
  // },
  {
    name: 'city',
    label: 'Cidade/Estado',
  },
  // {
  //   name: 'zipcode',
  //   label: 'CEP',
  //   options: {
  //     customBodyRender: (value) => {
  //       try {
  //         return value ? formatToCEP(value) : null
  //       } catch (err) {
  //         Sentry.captureException(err)
  //         return false
  //       }
  //     },
  //   },
  // },
]

export const columnsJobsHistory = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'cnpj',
    label: 'CNPJ',
    options: {
      customBodyRender: (value) => {
        try {
          return value ? <div style={{ width: 100 }}>{formatToCNPJ(value)}</div> : null
        } catch (err) {
          Sentry.captureException(err)
          return null
        }
      },
    },
  },
  {
    name: 'income',
    label: 'Salário',
  },
]

export const columnsUnemploymentInsuranceInfos = [
  {
    name: 'modality',
    label: 'Modalidade',
  },
  {
    name: 'state',
    label: 'Estado',
  },
  {
    name: 'value',
    label: 'Valor',
  },
  {
    name: 'date_emission',
    label: 'Data da Emissão',
  },
  {
    name: 'installment_number',
    label: 'Número da Parcela',
  },
  {
    name: 'installment_date',
    label: 'Data da Parcela',
  },
  {
    name: 'installment_situation',
    label: 'Situação da Parcela',
  },
]
