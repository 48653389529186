import React from 'react'
import _ from 'lodash'
import { useTheme } from '@material-ui/styles'

import { renderCheckService } from '../utilsFunctions'

import styles from './styles'

const ExpansionPanelNegativeMedia = ({ serviceInfos }) => {
  const theme = useTheme()
  const classes = styles()

  const renderNotice = (notice) => {
    return (
      <div className={classes.noticeContainer}>
        <hr className={classes.noticeHrSeparator} />
        <div>
          <strong>Título:</strong>
          {' '}
          <span>{notice.title}</span>
        </div>
        <div>
          <strong>Descrição:</strong>
          {' '}
          <span>{notice.description}</span>
        </div>
        <div>
          <strong>Data:</strong>
          {' '}
          <span>{notice.notice_date}</span>
        </div>
        <div>
          <strong>link:</strong>
          {' '}
          <a href={notice.link} target="_blank" rel="noopener noreferrer">Abrir</a>
        </div>
      </div>
    )
  }

  const renderNoticesType = (noticesType) => {
    const titles = {
      crimes_against_property: 'Crimes contra a propriedade',
      crimes_against_person: 'Crimes contra pessoa',
      crimes_against_honor: 'Crimes contra honra',
      crimes_against_public_adm: 'Crimes contra administração pública',
      crimes_against_sexual_dignity: 'Crimes contra a dignidade sexual',
      crimes_against_public_safety: 'Crimes contra a segurança pública',
      crimes_against_historic_property: 'Crimes contra bens históricos',
      crimes_against_justice_adm: 'Crimes contra a administração da justiça',
      economic_crimes: 'Crimes econômicos',
    }

    const noticesElem = _
    .chain(noticesType)
    .pickBy(x => !_.isEmpty(x))
    .map((v, k) => (
      <div key={k.toString()} className={classes.noticeTypeContainer}>
        <strong className={classes.noticeTypeTitle}>
          {`${titles[k]}: ${v.length}`}
        </strong>
        <div>
          {_.map(v, (x, i) => <div key={i.toString()}>{renderNotice(x)}</div>)}
        </div>
      </div>
    ))
    .value()

    return (<div>{noticesElem}</div>)
  }

  const renderNegativeMedia = () => {
    const service = _.find(serviceInfos, { service_id: 'negative_media' })
    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    const data = _.get(service, 'data')

    return (
      <div>
        <strong>Total de Notícias:</strong>
        {' '}
        <span>{data.total_notices}</span>
        {data.total_notices > 0 && renderNoticesType(data.notices)}
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: 16 }}>
      {renderNegativeMedia()}
    </div>
  )
}

export default ExpansionPanelNegativeMedia
