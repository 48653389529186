import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'

import SignedInLayout from 'ui/layouts/signedIn'

import Login from 'ui/views/auths/Login'
import PasswordChange from 'ui/views/auths/PasswordChange'
import PasswordConfirmRecover from 'ui/views/auths/PasswordConfirmRecover'
import PasswordRecover from 'ui/views/auths/PasswordRecover'

import IconsDemo from 'ui/views/demos/IconsDemo'
import ModalDemo from 'ui/views/demos/ModalDemo'
import ButtonsDemo from 'ui/views/demos/ButtonsDemo'
// import Plan from 'ui/views/Plan'
import Approved from 'ui/views/consults/Approved'
import Inexistent from 'ui/views/consults/Inexistent'
import ApprovedPep from 'ui/views/consults/ApprovedPep'
import ApprovedProcessesUnclassified from 'ui/views/consults/ApprovedProcessesUnclassified'
import ApprovedProcessesWhitelist from 'ui/views/consults/ApprovedProcessesWhitelist'
import ApprovedContingency from 'ui/views/consults/ApprovedContingency'
import Processing from 'ui/views/consults/Processing'
import Blacklist from 'ui/views/consults/Blacklist'
import Reproved from 'ui/views/consults/Reproved'
import Pending from 'ui/views/consults/Pending'
import Total from 'ui/views/consults/Total'
import Analytics from 'ui/views/analytics/Analytics'
import AnalyticsProcessTime from 'ui/views/analytics/AnalyticsProcessTime'
import AnalyticsServices from 'ui/views/analytics/AnalyticsServices'
import AnalyticsServicesProcessTime from 'ui/views/analytics/AnalyticsServicesProcessTime'
import ManualDerivationAnalysisInQueueAnalyst from 'ui/views/manualDerivation/Analysis/InQueueAnalyst'
import ManualDerivationAnalysisInQueueSupervisor from 'ui/views/manualDerivation/Analysis/InQueueSupervisor'
import ManualDerivationAnalysisInAnalysis from 'ui/views/manualDerivation/Analysis/InAnalysis'
import ManualDerivationAnalysisFinalizedApproved from 'ui/views/manualDerivation/Analysis/FinalizedApproved'
import ManualDerivationAnalysisFinalizedReproved from 'ui/views/manualDerivation/Analysis/FinalizedReproved'
import BatchProcessing from 'ui/views/batch/Processing'
import BatchProcessed from 'ui/views/batch/Processed'

import ConfigUser from 'ui/views/config/User'

import AdminUsers from 'ui/views/admin/Users'
import AdminOrganization from 'ui/views/admin/Organization'
import AdminOrganizationPlanCpf from 'ui/views/admin/OrganizationPlanCpf'
import AdminOrganizationPlanCnpj from 'ui/views/admin/OrganizationPlanCnpj'

import MasterOrganizations from 'ui/views/master/Organizations'

import { PANEL_ENVS } from 'utils/constants/environments'

import SecuredRoute from './securedRoute'

const renderRoutesConfig = () => {
  return [
    <SecuredRoute
      path="/config/user"
      parent={SignedInLayout}
      child={ConfigUser}
    />,
  ]
}

const renderRoutesAdmin = () => {
  return [
    <SecuredRoute
      path="/admin/users"
      parent={SignedInLayout}
      child={AdminUsers}
    />,

    <SecuredRoute
      path="/admin/organization"
      parent={SignedInLayout}
      child={AdminOrganization}
    />,

    <SecuredRoute
      path="/admin/organization-plan-cpf"
      parent={SignedInLayout}
      child={AdminOrganizationPlanCpf}
    />,

    <SecuredRoute
      path="/admin/organization-plan-cnpj"
      parent={SignedInLayout}
      child={AdminOrganizationPlanCnpj}
    />,
  ]
}

const renderRoutesMaster = () => {
  return [
    <SecuredRoute
      path="/master/organizations"
      parent={SignedInLayout}
      child={MasterOrganizations}
    />,
  ]
}

const renderRoutesByDoc = (docType) => {
  return [
    // <SecuredRoute
    //   type={docType}
    //   path={`/${docType}/plan`}
    //   parent={SignedInLayout}
    //   child={Plan}
    // />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/total`}
      parent={SignedInLayout}
      child={Total}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/approved`}
      parent={SignedInLayout}
      child={Approved}
    />,

    // <SecuredRoute
    //   type={docType}
    //   path={`/${docType}/in_attention`}
    //   parent={SignedInLayout}
    //   child={Attention}
    // />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/reproved`}
      parent={SignedInLayout}
      child={Reproved}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/inexistent`}
      parent={SignedInLayout}
      child={Inexistent}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/processing`}
      parent={SignedInLayout}
      child={Processing}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/blocked`}
      parent={SignedInLayout}
      child={Blacklist}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/pending`}
      parent={SignedInLayout}
      child={Pending}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/analytics-consults`}
      parent={SignedInLayout}
      child={Analytics}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/analytics-services`}
      parent={SignedInLayout}
      child={AnalyticsServices}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/analytics-time-consults`}
      parent={SignedInLayout}
      child={AnalyticsProcessTime}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/analytics-time-services`}
      parent={SignedInLayout}
      child={AnalyticsServicesProcessTime}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/batch/processing`}
      parent={SignedInLayout}
      child={BatchProcessing}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/batch/processed`}
      parent={SignedInLayout}
      child={BatchProcessed}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/approved-pep`}
      parent={SignedInLayout}
      child={ApprovedPep}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/approved-processes-whitelist`}
      parent={SignedInLayout}
      child={ApprovedProcessesWhitelist}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/approved-processes-unclassified`}
      parent={SignedInLayout}
      child={ApprovedProcessesUnclassified}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/approved-contingency`}
      parent={SignedInLayout}
      child={ApprovedContingency}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/manual-analysis/in-queue-analyst`}
      parent={SignedInLayout}
      child={ManualDerivationAnalysisInQueueAnalyst}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/manual-analysis/in-queue-supervisor`}
      parent={SignedInLayout}
      child={ManualDerivationAnalysisInQueueSupervisor}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/manual-analysis/in-analysis`}
      parent={SignedInLayout}
      child={ManualDerivationAnalysisInAnalysis}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/manual-analysis/finalized-approved`}
      parent={SignedInLayout}
      child={ManualDerivationAnalysisFinalizedApproved}
    />,

    <SecuredRoute
      type={docType}
      path={`/${docType}/manual-analysis/finalized-reproved`}
      parent={SignedInLayout}
      child={ManualDerivationAnalysisFinalizedReproved}
    />,
  ]
}

const renderRoutesDemo = () => {
  if (process.env.NODE_ENV !== 'development') return null

  return [
    <SecuredRoute
      path="/icons-demo"
      parent={SignedInLayout}
      child={IconsDemo}
    />,

    <SecuredRoute
      path="/modal-demo"
      parent={SignedInLayout}
      child={ModalDemo}
    />,

    <SecuredRoute
      path="/buttons-demo"
      parent={SignedInLayout}
      child={ButtonsDemo}
    />,
  ]
}

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/password-change" exact component={PasswordChange} />
        <Route path="/password-confirm-recover" exact component={PasswordConfirmRecover} />
        <Route path="/password-recover" exact component={PasswordRecover} />

        {renderRoutesDemo()}

        {renderRoutesConfig()}
        {renderRoutesAdmin()}
        {renderRoutesMaster()}

        {renderRoutesByDoc(PANEL_ENVS.CPF)}
        {renderRoutesByDoc(PANEL_ENVS.CNPJ)}

        <Route component={Login} />
      </Switch>
    </BrowserRouter>
  )
}
