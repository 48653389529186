import React from 'react'
import _ from 'lodash'
import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { renderCheckService } from 'ui/components/ContainerCommonPanels/utilsFunctions'
import { renderCreditScore } from 'ui/components/ContainerCommonPanels/CreditScore'
import CreditPersonRestrictions from 'ui/components/ContainerCommonPanels/CreditPersonRestrictions'
import CreditRestrictions from 'ui/components/ContainerCommonPanels/CreditRestrictions'

const ExpansionPanelCreditHistory = ({ serviceInfos }) => {
  const theme = useTheme()

  const renderCreditPersonRestrictions = (service) => (
    <CreditPersonRestrictions service={service} />
  )

  const renderCreditRestrictions = (service) => (
    <CreditRestrictions service={service} />
  )

  const renderService = (service) => {
    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    if (!_.get(service, 'data.consists')) {
      return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
    }

    return <strong style={{ color: theme.palette.primary.red }}>Sim</strong>
  }

  const renderCreditScoreCnpj = (service) => (
    renderCreditScore(service, theme)
  )

  const renderGrid = (key, title, children) => {
    return (
      <Grid key={key} container spacing={2} style={{ marginBottom: 20 }}>
        <Grid item xs={3}>
          <div>{title}</div>
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: 20 }}>
          {children}
        </Grid>
      </Grid>
    )
  }

  const renderListServices = () => {
    const serviceIds = [
      'credit_legal_person_restrictions',
      'credit_restrictions_cnpj',
      'credit_score_cnpj',
    ]

    return _.map(serviceIds, (serviceId) => {
      const service = _.find(serviceInfos, { service_id: serviceId })
      if (_.isEmpty(service)) return null

      let functionRender = null
      switch (serviceId) {
        case 'credit_legal_person_restrictions':
          functionRender = renderCreditPersonRestrictions
          break
        case 'credit_score_cnpj':
          functionRender = renderCreditScoreCnpj
          break
        case 'credit_restrictions_cnpj':
          functionRender = renderCreditRestrictions
          break
        default:
          functionRender = renderService
          break
      }

      return renderGrid(serviceId, service.name_short, functionRender(service))
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        fontSize: 16,
      }}
    >
      {renderListServices()}
    </div>
  )
}

export default ExpansionPanelCreditHistory
